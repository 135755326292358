<template>
    <div>
        <div id="qr-reader" style="width: 100%;"></div>
        <div v-if="qrCodeMessage" class="result">
            Scanned QR Code: {{ qrCodeMessage }}
        </div>
    </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import HttpHelpers from "@/store/httpHelpers";

export default {
    data() {
        return {
            qrCodeMessage: "",
            html5QrCode: null,
        };
    },
    methods: {
        onScanSuccess(decodedText) {
            this.qrCodeMessage = decodedText;
        },
        onScanFailure() {
            this.qrCodeMessage = '';
        },
    },
    mounted() {
        const config = { fps: 10, qrbox: 250 };

        this.html5QrCode = new Html5Qrcode("qr-reader");
        this.html5QrCode.start(
            { facingMode: "environment" },
            config,
            this.onScanSuccess,
            this.onScanFailure
        );
    },
    beforeDestroy() {
        if (this.html5QrCode) {
            this.html5QrCode.stop();
        }
    },
    watch: {
        qrCodeMessage: function(decodedTxt) {
            let pattern = /([A-Za-z])(\d+)/g;
            let matches = decodedTxt.matchAll(pattern);
            let scope = this;

            let parsed_data = {};
            for(let match of matches) {
                parsed_data[match[1]] = match[2];
            }

            HttpHelpers.post('tracker_invoice/get_link', parsed_data).then(function(res) {
                scope.$router.push(res.data.url);
            });
        }
    }
};
</script>

<style scoped>
.result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}
</style>
