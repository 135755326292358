<template>
    <div v-if="loggedIn">
        <div>
            <component v-bind:is="currentComponent"></component>
        </div>
    </div>
    <div v-else-if="!loggedIn">
        <SignInPage></SignInPage>
    </div>
</template>

<script>
import SignInPage from "@/views/SignInPage";
export default {
    name: "ToolsPage",
    components: {
        SignInPage,
        catalog_order: () => import('@/components/tools/CatalogOrder.vue'),
        clock: () => import('@/components/tools/TimeClock/TimeClock.vue'),
        inventory_manager: () => import('@/components/tools/InventoryManager/Container.vue'),
        invoicing: () => import('@/components/tools/Invoicing'),
        po_receiving: () => import('@/components/tools/POReceiving.vue'),
        scrap_tracker: () => import('@/components/tools/ScrapTracker.vue'),
        consolidation: () => import('@/components/tools/ShipConsolidate.vue'),
        status_board: () => import('@/components/tools/StatusBoard.vue'),
        warehouse_order: () => import('@/components/tools/WarehouseOrder.vue'),
        estimator_material_costs: () => import('@/components/tools/EstimatorMaterialCosts.vue'),
        dev_tools: () => import('@/components/tools/DevTools.vue'),
        sales_goals: () => import('@/components/tools/SalesGoals'),
        cogs_upload: () => import('@/components/tools/cogs_upload.vue'),
        surcharge: () => import('@/components/tools/surcharge/surcharge.vue'),
        config: () => import('@/components/tools/Config/config.vue'),
    },
    data() {
        return {
            appState: this.$appStore.state,
        }
    },
    computed: {
        currentComponent: function() {
            let path = this.$route.path;
            return path.substring(1);
        },
        loggedIn: function() {
            return this.appState.loggedIn
        }
    },
}
</script>

<style scoped>

</style>